
<template>
  <div>
    <a
      href
      @click.prevent="showModal('0')"
      v-if="params.colDef.field=='company_name'"
    >{{this.params.data[params.colDef.field]}}</a>
    <a
      href
      @click.prevent="showModal('0')"
      v-else
      :style="params.data.contract_budgetactive==1?'color:green':'color:red'"
    >{{this.params.data[params.colDef.field]}}</a>
  </div>

  <!--  -->
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      params: null,
      ID: null,
      popupActive: false
    };
  },
  beforeMount() {
    this.ID = this.params.data.hasOwnProperty("mou_id")
      ? this.params.data.mou_id
      : 0;
  },
  beforeDestroy() {},
  methods: {
    showModal: function(index) {
      let data = {
        tabIndex: index,
        ID: this.ID,
        tabIndexView: "1"
      };
      // console.log(
      //   data,
      //   "fsdfdsfdsfsdfds",
      //   this.params.data.hasOwnProperty("draft_id")
      // );
      if (this.params.data.hasOwnProperty("draft_id")) {
        //  console.log(this.params.data, "draft");
        if (this.params.data.contract_type == 2) {
          data["tabIndex"] = 1;
          data["draft_id_fla"] = this.params.data.draft_id;
          eventBus.$emit("switchFormEditTabView", data);
          console.log(data, "FLAAAAAAAAAAAA");
          setTimeout(() => {
            eventBus.$emit("switchFormEditTabFla", data);
          }, 1000);
        } else {
          data["draft_id"] = this.params.data.draft_id;
          eventBus.$emit("switchFormEditTabView", data);
          setTimeout(() => {
            eventBus.$emit("switchFormEditTab", data);
          }, 1000);
        }
      } else {
        eventBus.$emit("switchFormEditTabView", data);
        setTimeout(() => {
          eventBus.$emit("switchFormEditTab", data);
        }, 1000);
      }
    },

    privStatus: function() {
      this.popupActive = false;
    }
  }
});
</script>
