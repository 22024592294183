<template>
  <div>
    <vx-card class="form-title mb-6" title="SELECT">
      <form>
        <div class="row mb-4">
          <div class="col-6 mb-2">
            <h6>Company Name</h6>
            <multiselect
              v-model="form.companySelected"
              track-by="company_id"
              label="company_name"
              :options="companies"
              name="Company Name"
              placeholder="Select Company"
              :searchable="true"
              :allow-empty="true"
              open-direction="bottom"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{
                option.company_name
                }}
              </template>
            </multiselect>
            <p class="error-msg">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Company Name')"
              >{{ errors.first("Company Name") }}</span>
            </p>
          </div>

          <div class="col-6 mb-2">
            <h6>Type of Agreement</h6>
            <multiselect
              label="name"
              class="w-full"
              track-by="id"
              v-model="form.typeOfAgreement"
              name="contract Form"
              :options="formType"
              :searchable="true"
              :allow-empty="false"
              placeholder="Select Option"
              open-direction="bottom"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{
                option.name
                }}
              </template>
            </multiselect>
            <p class="error-msg text-sm">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Vender Name')"
              >{{ errors.first("Vender Name") }}</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-2">
            <h6>Project Name</h6>
            <!-- <vs-input
              type="text"
              name="Project Name"
              v-model="form.projectSelected"
              class="w-full"
            />
            <p class="error-msg text-sm">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Project Name')"
              >{{ errors.first("Project Name") }}</span>
            </p>-->

            <multiselect
              v-model="form.projectSelected"
              track-by="project_id"
              label="project_name"
              @select="onProjectSelect"
              :options="projects"
              name="Project Name"
              placeholder="Select Project"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{
                option.project_name
                }}
              </template>
            </multiselect>
            <p class="error-msg">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Project Name')"
              >{{ errors.first("Project Name") }}</span>
            </p>
          </div>
          <div class="col-6 mb-2">
            <h6>Category</h6>
            <multiselect
              label="category_name"
              class="w-full"
              track-by="category_id"
              @select="onSuggestSelect"
              v-model="form.category"
              name="Category Name"
              :options="categoryList"
              :disabled="categoryList.length==0"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              placeholder="Select Option"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{
                option.category_name
                }}
              </template>
            </multiselect>
            <p class="error-msg text-sm">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Category Name')"
              >{{ errors.first("Category Name") }}</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-2">
            <h6>Subcategory</h6>
            <multiselect
              label="category_name"
              class="w-full"
              track-by="category_id"
              v-model="form.subcategory"
              name="Subcategory Name"
              :options="subcategoryList"
              :disabled="subcategoryList.length==0"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              placeholder="Select Option"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{
                option.category_name
                }}
              </template>
            </multiselect>
            <p class="error-msg text-sm">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Subcategory Name')"
              >{{ errors.first("Subcategory Name") }}</span>
            </p>
          </div>
          <div class="col-6 mb-2">
            <h6>UserName</h6>
            <multiselect
              label="user_name"
              class="w-full"
              track-by="user_id"
              v-model="form.assignee"
              name="Assignee Name"
              :options="assigneeList"
              :searchable="true"
              :allow-empty="false"
              open-direction="bottom"
              placeholder="Select Option"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{
                option.user_name
                }}
              </template>
            </multiselect>
            <p class="error-msg text-sm">
              <span
                class="text-danger text-sm"
                v-show="errors.has('Assignee Name')"
              >{{ errors.first("Assignee Name") }}</span>
            </p>
          </div>
        </div>

        <!-- SUBMIT BUTTON -->
        <div class="vx-row">
          <div class="vx-col mx-auto">
            <!-- :disabled="submitStatus" -->
            <vs-button type="filled" @click.prevent="submitForm" class="mr-3">Submit</vs-button>

            <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button>
          </div>
        </div>
      </form>
    </vx-card>

    <vx-card class="form-title" title="LIST">
      <div class="contractFilterTable mb-4">
        <ul class="list-inline">
          <li
            class="list-inline-item min-w-25 border-left align-top mr-0 min-vh-100"
            v-for="(status, statusIndex) in statusData"
            :key="statusIndex"
            v-if="status.contractList.length>0"
          >
            <div
              class="font-weight-bold py-3 border-bottom px-2 headTitle"
            >{{status.statusName}} ({{status.contractList.length}})</div>
            <div>
              <div
                class="border-bottom p-2 addBtn text-center"
                :class="statusIndex == 0 ? 'hoverEffect' : ''"
                @click="addForm(statusIndex)"
              >{{statusIndex == 0 ? "+ Add form" : ""}}</div>
              <div
                class="border-bottom mb-5 border-top px-2"
                v-for="(contractData, contractDataInd) in status.contractList"
                :key="contractDataInd"
                @click="showModal(contractData)"
                style="cursor:pointer"
              >
                <p>
                  <span class="text-primary mr-1">User:</span>
                  <span>{{contractData.userName}}</span>
                </p>
                <p>
                  <span class="text-primary mr-1">Vendor Name:</span>
                  <span>{{contractData.vendor_name}}</span>
                </p>

                <p>
                  <span class="text-primary mr-1">Project:</span>
                  <span>{{contractData.projectName}}</span>
                </p>
                <p>
                  <span class="text-primary mr-1">Due Date:</span>
                  <span>{{ contractData.dueDate }}</span>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
    </vx-card>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index.js";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import ContractService from "@/services/ContractService.js";
import projectService from "@/services/projectService.js";
import CategoryService from "@/services/categoryService.js";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      projects: [],
      totalPages: 0,
      maxPageNumbers: 7,
      companies: [],
      formType: [
        { name: "MOU", id: "1" },
        { name: "LFA", id: "2" },
        { name: "FLA", id: "3" },
        { name: "FLA Acquisition", id: "4" },
      ],
      assigneeList: [],
      categoryList: [],
      subcategoryList: [],
      //   abc: [
      //     {
      //       key: "Open",
      //       contractList: ["Ajit", "Amit", "Pratik"]
      //     },
      //     {
      //       key: "Close",
      //       contractList: ["Ajit"]
      //     },
      //     {
      //       key: "new",
      //       contractList: []
      //     },
      //     {
      //       key: "CloseAdd",
      //       contractList: ["Ajit", "ff", "ss", "d", "dd"]
      //     },
      //     {
      //       key: "Close",
      //       contractList: ["Ajit"]
      //     },
      //     {
      //       key: "new",
      //       contractList: []
      //     },
      //     {
      //       key: "CloseAdd",
      //       contractList: ["Ajit", "ff", "ss", "d", "dd"]
      //     },
      //     {
      //       key: "Close",
      //       contractList: ["Ajit"]
      //     },
      //     {
      //       key: "new",
      //       contractList: []
      //     },
      //     {
      //       key: "CloseAdd",
      //       contractList: ["Ajit", "ff", "ss", "d", "dd"]
      //     }
      //   ],

      form: {
        assignee: "",
        category: "",
        subcategory: "",
        projectSelected: "",
        companySelected: "",
        typeOfAgreement: "",
      },
      statusData: [],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
      }
    },
  },
  computed: {
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getContractStatusData(val);
          return val;
        } else return 1;
      },
    },
  },
  beforeMount() {
    this.getCompanyList();
    this.getAgreement();
    this.getProjectsList();

    //this.getcategoryList();
    this.getContractStatusData(this.currentPage);
  },
  methods: {
    onProjectSelect(selected) {
      this.getAllCategoryListByProject(selected.project_id);
    },
    getAllCategoryListByProject: function (id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoryList = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getProjectsList: function () {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAgreement: function () {
      let filters = {
        contractType: "Agreement",
      };

      ContractService.getContractVendorListMou(filters)
        .then((response) => {
          const { data } = response;
          //  console.log(data);
          if (!data.err) {
            this.formType = data.data;
            let array = data.data.filter((x) => {
              return x.contract_type == 1;
            });
            if (array.length > 0) {
              this.form.typeOfAgreement = array[0];
            } else {
              this.form.typeOfAgreement = data.data[0];
            }
            this.getContractStatusData(this.currentPage);

            // this.totalPages = data.pagination.last_page;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addForm: function (ind) {
      if (ind === 0) {
        let data = {
          tabIndex:
            this.form.typeOfAgreement.id == 1 ||
            this.form.typeOfAgreement.id == 2
              ? "0"
              : "1",
          ID: 0,
          tabIndexView: "1",
        };
        console.log("data FLAAAA :>> ", data);
        eventBus.$emit("switchFormEditTabView", data);
        if (
          this.form.typeOfAgreement.id == 1 ||
          this.form.typeOfAgreement.id == 2
        ) {
          console.log("fsdfdsfdsf");
          setTimeout(() => {
            eventBus.$emit("contractTypeMou", this.form.typeOfAgreement);
          }, 1000);
        } else {
          setTimeout(() => {
            eventBus.$emit("contractTypeFla", this.form.typeOfAgreement);
          }, 1000);
        }
      }
    },
    showModal: function (contractData) {
      // console.log(contractData);
      // return false;
      let data = {
        tabIndex: this.form.typeOfAgreement.contract_type == 1 ? "0" : "1",
        ID:
          this.form.typeOfAgreement.contract_type == 1
            ? contractData.mou_id
            : contractData.fla_id,
        tabIndexView: "1",
      };
      //  console.log("data FLAAAA :>> ", data);
      eventBus.$emit("switchFormEditTabView", data);
      if (this.form.typeOfAgreement.contract_type == 1) {
        setTimeout(() => {
          eventBus.$emit("switchFormEditTab", data);
        }, 1000);
      } else {
        setTimeout(() => {
          eventBus.$emit("switchFormEditTabFla", data);
        }, 1000);
      }
    },
    onSuggestSelect: function (selected) {
      this.getSubcategoryList(
        selected.category_id,
        this.form.projectSelected.project_id
      );
    },
    getSubcategoryList: function (ID, project_id) {
      // let pCateId = this.categoryValue.category_id;
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    getcategoryList: function () {
      let filters = {
        contractType: "Category",
      };
      ContractService.getContractVendorListMou(filters)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!data.err) {
            this.categoryList = data.data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCompanyList: function () {
      let filters = {
        contractType: "Company",
      };
      ContractService.getContractVendorListMou(filters)
        .then((response) => {
          const { data } = response;
          console.log(data);
          if (!data.err) {
            this.companies = data.data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getContractStatusData: function (currentPage) {
      if (this.form.typeOfAgreement == "") {
        return false;
      }
      this.$vs.loading();
      let payload = {
        page: currentPage,
        id: this.form.typeOfAgreement.id,
        // category_id:
        //   this.form.category == "" ? "" : this.form.category.category_id,
        category_id:
          this.form.subcategory == "" ? "" : this.form.subcategory.category_id,
        user_id: this.form.assignee == "" ? "" : this.form.assignee.user_id,
        company_id:
          this.form.companySelected == ""
            ? ""
            : this.form.companySelected.company_id,
        project:
          this.form.projectSelected == ""
            ? ""
            : this.form.projectSelected.project_id,
      };

      if (this.form.typeOfAgreement.hasOwnProperty("contract_type")) {
        payload["contractType"] = this.form.typeOfAgreement.contract_type;
      }

      ContractService.getStatusData(payload)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          //   console.log("data.data :>>%%%% ", data);
          if (data.Status) {
            this.statusData = data.data;
            let count = 0;
            this.statusData.map((x) => {
              if (x.contractList.length > 0) {
                count = count + 1;
              }
            });
            if (count == 0) {
              this.$vs.notify({
                title: "Error!",
                text: "No Data Found",
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
            this.assigneeList = data.userDropdownList;
            // this.totalPages = data.pagination.last_page;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    submitForm: function () {
      this.$vs.loading();
      this.getContractStatusData(this.currentPage);

      this.$vs.loading.close();
    },
    clearForm: function () {
      this.form = {
        assignee: "",
        category: "",
        subcategory: "",
        projectSelected: "",
        companySelected: "",
        typeOfAgreement: "",
      };
      this.currentPage = 1;
      //  this.getContractStatusData(this.currentPage);
      this.getAgreement();
    },
  },
};
</script>

<style>
</style>
