
<template>
  <div>
    <a
      href
      @click.prevent="showModal('1')"
      v-if="params.colDef.field=='company_name'"
    >{{this.params.data[params.colDef.field]}}</a>
    <a
      href
      @click.prevent="showModal('1')"
      v-else
      :style="params.data.contract_budgetactive==1?'color:green':'color:red'"
    >{{this.params.data[params.colDef.field]}}</a>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      params: null,
      ID: null,
      popupActive: false
    };
  },
  beforeMount() {
    console.log("this.params.data :>> ", this.params.data);
    console.log("this.params.data.value :>> ", this.params.data.company_name);
    this.ID = this.params.data.fla_id;
  },
  beforeDestroy() {},
  methods: {
    showModal: function(index) {
      let data = {
        tabIndex: index,
        ID: this.ID,
        tabIndexView: "1"
      };
      console.log("data FLAAAA :>> ", data);
      eventBus.$emit("switchFormEditTabView", data);
      setTimeout(() => {
        eventBus.$emit("switchFormEditTabFla", data);
      }, 1000);
    },
    privStatus: function() {
      this.popupActive = false;
    }
  }
});
</script>
